<template>
    <div class="bi-table">
        <div class="controller-items slide-in-left" v-if="controller_items">
            <p class="items-selceted">
                נבחרו {{selected_rows.length}} מוצרים
            </p>
            <div class="controller-btn">
                <p>לקוחות שקנו</p>
            </div>
            <div class="controller-btn">
                <p>סוכנים שמכרו</p>
            </div>
            <div class="controller-btn">
                <p>חשבוניות</p>
            </div>
        </div>

        <div class="top-bar">
            <div class="date-op">
                <p>תאריך התחלה: </p>
                <input type="date" v-model="date_start">
            </div>
            <div class="date-op">
                <p>תאריך סיום: </p>
                <input type="date" v-model="date_end">
            </div>
            <div class="go-btn" @click="get_report_data">
                <i class="material-icons" style="font-size: 30px;">check_circle</i>
            </div>
        </div>
        <div class="secondary-bar">
            <div class="group-filter" :style="check_current_group('פריטים')" @click="current_filter_group = 'פריטים'">
                <p>פריטים</p>
            </div>
            <div class="group-filter" :style="check_current_group('ספקים')" @click="current_filter_group = 'ספקים'">
                <p>ספקים</p>
            </div>
            <div class="group-filter" :style="check_current_group('לקוחות')" @click="current_filter_group = 'לקוחות'">
                <p>לקוחות</p>
            </div>
            <div class="group-filter" :style="check_current_group('סוכנים')" @click="current_filter_group = 'סוכנים'">
                <p>סוכנים</p>
            </div>
            <div class="group-filter" :style="check_current_group('חשבוניות')" @click="current_filter_group = 'חשבוניות'">
                <p>חשבוניות</p>
            </div>
        </div>
        <div class="report-table">
            <div class="loading" v-if="loading">
                <i class="el-icon-loading" style="font-size:40px; margin-bottom: 10px;"></i>
                <p>טוען נתונים, אנא המתן...</p>
            </div>
            <table id="table" v-if="current_filter_group == 'פריטים'">
                <tr>
                    <th>בחר</th>
                    <th>מקט</th>
                    <th>שם</th>
                    <th>כמות</th>
                    <th>מ.מינ</th>
                    <th>מ.מקס</th>
                    <th>מ.ממוצע</th>
                    <th>סכום</th>
                    <th>תרומה</th>
                </tr>
                <template v-for="item in products" :key="item.makat">
                    <tr>
                        <td style="width:30px;">
                            <div class="select-row" @click="select_row(item)" :style="is_selected(item)">
                            </div>
                        </td>
                        <td>{{item.makat}}</td>
                        <td>{{item.name}}</td>
                        <td>{{item.amount}}</td>
                        <td>{{item.min_price}}</td>
                        <td>{{item.max_price}}</td>
                        <td>{{calculate_avg_price(item.clients).toFixed(2)}}</td>
                        <td>{{item.income.toFixed(2)}}</td>
                        <td>{{(item.income / total_report_income * 100).toFixed(2)}}%</td>
                    </tr>
                </template>
                <tr style="position: sticky; bottom: 26px; background:var(--purple); color:white;  text-shadow: 0 0 2px black;">
                    <td></td>
                    <td>{{products.length}}</td>
                    <td></td>
                    <td>{{total_report_pcs.toFixed(2)}}</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>{{total_report_income.toFixed(2)}}</td>
                    <td><p style="color:white;">100%</p></td>
                </tr>
                <tr style="position: sticky; bottom: 0; background:var(--success); color:white; text-shadow: 0 0 2px black;" v-if="selected_rows_computed">
                    <td>{{selected_rows_computed.total_items}}</td>
                    <td>{{selected_rows_computed.total_items}}</td>
                    <td>
                        <p class="clean-selected" @click="selected_rows = [], close_selected_actions()">בטל בחירה</p>
                    </td>
                    <td>{{selected_rows_computed.total_amount}}</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>{{selected_rows_computed.total_income.toFixed(2)}}</td>
                    <td>{{selected_rows_computed.total_truma.toFixed(2)}}%</td>
                </tr>
            </table>

            <table id="table" v-if="current_filter_group == 'לקוחות'">
                <tr>
                    <th>מקט</th>
                    <th>שם</th>
                    <th>סוכן</th>
                    <th>הזמנות</th>
                    <th>הכנסה</th>
                    <th>החזרות</th>
                </tr>
                <template v-for="client in clients" :key="client.makat">
                    <tr>
                        <td>{{client.makat}}</td>
                        <td>{{client.name}}</td>
                        <td>{{client.agent}}</td>
                        <td>
                            <p style="color:var(--blue); cursor:pointer;">{{client.orders}}</p>
                        </td>
                        <td>{{client.income.toFixed(2)}}</td>
                        <td>{{client.returns.toFixed(2)}}</td>
                    </tr>
                </template>
            </table>
        </div>
    </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import Swal from 'sweetalert2';
import { projectFunctions } from '../../firebase/config';
import { computed, watch } from '@vue/runtime-core';
export default {
setup(){
    const loading = ref(false);
    const date_start = ref(null);
    const date_end = ref(null);
    const current_filter_group = ref(null);
    
    const report_raw_data = ref(null);
    const products = ref([]);
    const total_report_income = ref(0);
    const total_report_pcs = ref(0);

    const selected_rows = ref([]);

    const get_report_data = async () => {
        loading.value = true;

        report_raw_data.value = null;
        products.value = [];
        total_report_income.value = 0;
        total_report_pcs.value = 0;
        current_filter_group.value = null;

        if(!date_start.value || !date_end.value){
            Swal.fire({
                icon: 'error',
                title: 'שגיאה',
                text: 'אנא בחר תאריך התחל הותאריך סיום לדו"ח'
            })
            loading.value = false
            return
        }
        const results = await projectFunctions.httpsCallable("get_invoices_by_date", {timeout: 540000})({from: date_start.value, to: date_end.value})
        console.log(results);
        if(results.data && results.data.length > 0){
            report_raw_data.value = results.data;
            current_filter_group.value = 'פריטים'
            await compute_products();
        }else{
            Swal.fire({
                icon: 'error',
                title: 'שגיאה',
                text: 'לא נמצאו נתונים בהתאם לתאריכים המבוקשים'
            })
            loading.value = false
            return
        }
        loading.value = false;
    }

    const compute_products = async () => {
        for(let i =0; i< report_raw_data.value.length; i++){
            let invoice = report_raw_data.value[i]
            let items = report_raw_data.value[i].AINVOICEITEMS_SUBFORM
            if(items && items.length > 0){
                for(let j =0; j < items.length; j++){
                    let item = items[j]
                    let _item = {
                        name: item.PDES,
                        makat: item.PARTNAME,
                        clients: [{
                            price: item.DISPRICE,
                            client: invoice.CUSTNAME,
                            amount: item.QUANT,
                            agent: invoice.AGENTNAME
                        }],
                        min_price: item.DISPRICE,
                        max_price: item.DISPRICE,
                        amount: item.QUANT,
                        income: item.QPRICE
                    }
                    let inx = products.value.findIndex(itm => {
                        return itm.makat == _item.makat
                    })
                    if(inx == -1){
                        products.value.push(_item);
                    }else{
                        products.value[inx].amount += _item.amount;
                        products.value[inx].income += _item.income;
                        products.value[inx].clients.push(_item.clients[0]);
                        if(products.value[inx].min_price > _item.min_price){
                            products.value[inx].min_price = _item.min_price
                        }
                        if(products.value[inx].max_price < _item.max_price){
                            products.value[inx].max_price = _item.max_price
                        }
                        
                    }
                    total_report_pcs.value += _item.amount
                    total_report_income.value += _item.income
                }
            }
        }
        products.value = products.value.sort((aa,bb) => {
            return bb.amount - aa.amount
        })
        console.log('Done with Items');
        console.log(total_report_income.value);
    }

    const calculate_avg_price = (arr) => {
        let total = 0;
        arr.forEach(sale => {
            total += sale.price
        })
        return total / arr.length
    }

    const clients = ref(computed(() => {
        let clients_arr = [];
        if(report_raw_data.value){
            for(let i =0; i< report_raw_data.value.length; i++){
                let invoice = report_raw_data.value[i]
                let client = {
                    name: invoice.CDES,
                    agent: invoice.AGENTNAME,
                    makat: invoice.CUSTNAME,
                    income: invoice.QPRICE,
                    orders: 1,
                    returns: 0,
                    items: invoice.AINVOICEITEMS_SUBFORM
                }
                if(invoice.QPRICE < 0){
                    client.returns = invoice.QPRICE
                }
                let inx = clients_arr.findIndex(_client => {
                    return _client.makat == client.makat
                })
                if(inx == -1){
                    if(client.makat){
                        clients_arr.push(client)
                    }
                }else{
                    clients_arr[inx].income += client.income;
                    clients_arr[inx].returns += client.returns;
                    clients_arr[inx].orders += client.orders;
                }
            }
        }
        return clients_arr
    }))


    const check_current_group = (test) => {
        if(test == current_filter_group.value){
            return 'background: var(--success)'
        }else{
            return 'background: var(--main)'
        }
    }

    const select_row = (row) => {
        let inx = selected_rows.value.findIndex(_row => {
            return _row == row
        })
        if(inx == -1){
            selected_rows.value.push(row);
        }else{
            selected_rows.value.splice(inx, 1);
        }
        if(selected_rows.value.length > 0){
            open_selected_actions();
        }else{
            close_selected_actions();
        }
    }

    const is_selected = (row) => {
        let inx = selected_rows.value.findIndex(_row => {
            return _row == row
        })
        if(inx == -1){
            return 'background: var(--secondary)'
        }else{
            return 'background: var(--success)'
        }
    }

    const selected_rows_computed = ref(computed(() => {
        if(current_filter_group.value == 'פריטים'){
            let total_items = 0;
            let total_income = 0;
            let total_truma = 0;
            let total_amount = 0;
            selected_rows.value.forEach(item => {
                total_items += 1;
                total_amount += item.amount;
                total_income += item.income;
                total_truma = total_income / total_report_income.value * 100
            })
            return {total_items, total_income, total_truma, total_amount}
        }
        return
    }))

    const controller_items = ref(false);
    const open_selected_actions = () => {
        //items
        if(selected_rows.value[0].min_price){
            controller_items.value = true;
        }
    }

    const close_selected_actions = () => {
        controller_items.value = false;
    }

    return{
        current_filter_group, date_start, date_end, check_current_group, get_report_data, loading,
        products, calculate_avg_price, total_report_income, clients, total_report_pcs, selected_rows,
        select_row, is_selected, selected_rows_computed, open_selected_actions, controller_items,
        close_selected_actions,
    }
}
}
</script>

<style scoped>
.bi-table{
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.top-bar{
    margin-top: 5px;
    width: 100%;
    height: 50px;
    background: var(--secondary);
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow-x: auto;
    padding: 0 5px 0 5px;
}
.secondary-bar{
    width: 100%;
    height: 50px;
    background: var(--secondary);
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow-x: auto;
    padding: 0 5px 0 5px;
}
.date-op{
    width: fit-content;
    margin-left: 5px;
    display: flex;
    flex-direction: column;
    color: var(--yellow);
}
.go-btn{
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--success);
    margin-right: 5px;
    cursor: pointer;
}
.group-filter{
    width: fit-content;
    padding: 5px;
    background: var(--main);
    border-radius: 5px;
    min-width: 100px;
    height: 90%;
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    text-shadow: 0 0 3px black;
    cursor: pointer;
}
.report-table{
    position: relative;
    width: 100%;
    height: calc(100% - 50px - 50px);
    overflow-y: auto;
}
.select-row{
    width: 100%;
    height: 25px;
    background: var(--secondary);
    cursor: pointer;
    border-radius: 10px;
    transition: 0.25s;
}
.loading{
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
    text-shadow: 0 0 3px black;
    flex-direction: column;
    background: var(--main);
}
#table {
    border-collapse: collapse;
    width: 100%;
}
#table td, #table th {
  border: 1px solid #ddd;
  padding: 3px;
  text-align: center;
}
#table tr:nth-child(even){background-color: #f2f2f2;}
#table tr:nth-child(odd){background-color: #e7e6e6;}
#table tr:hover {background-color: #ddd;}
#table th {
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: center;
  background-color: var(--purple);
  color: white;
  position: sticky;
  top: 0;
}
.clean-selected{
    background: var(--danger);
    width: fit-content;
    border-radius: 3px;
    padding: 0 5px 0 5px;
    cursor: pointer;
}
.controller-items{
    position: absolute;
    top: 5px;
    left: 0;
    width: 160px;
    height: fit-content;
    padding-bottom: 5px;
    background: var(--main);
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.432);
    display: flex;
    flex-direction: column;
    overflow-x: auto;
    z-index: 10;
    transition: 0.25s;
}
.items-selceted{
    width: 100%;
    text-align: center;
    flex-shrink: 0;
    height: 30px;
    background: var(--purple);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    color: var(--light);
}
.controller-btn{
    width: 100%;
    margin: 5px 0 0 0;
    height: 50px;
    background: var(--secondary);
    color: white;
    flex-shrink: 0;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--yellow);
    transition: 0.25s;
    text-shadow: 0 0 3px black;
}
.controller-btn:hover{
    background: var(--success);
    color: white;
}




.slide-in-left {
	-webkit-animation: slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
@-webkit-keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
            transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
            transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}

</style>